(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championship.classification
   * @description
   *
   */
  angular
    .module('neo.public.classification', [
      'ui.router'
    ]);
}());
